<template lang="html">
  <div class="data-report">
    <NavBar :list="[{ name: '数据报表' }]" name="数据报表"></NavBar>
    <div class="public-box">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="市场" v-if="isFinance">
          <el-select v-model="formData.search_market_id" clearable placeholder="请选择市场">
            <el-option v-for="item in marketList" :key="item.market_id" :value="item.market_id"
              :label="item.market_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看年份">
          <div class="selectBox">
            <div class="selectLeft">
              <div class="list" v-for="(item, index) in yearArr" :key="index">
                <el-checkbox v-model="item.checked" :value="item.value" :disabled="item.disabled"
                  @change="changeYear(item)">{{ item.name }}</el-checkbox>
              </div>
            </div>
            <div class="selectBtn">
              <MyButton title="合并" @click="yearMerge"></MyButton>
            </div>
            <div class="selectRight">
              <div class="list" v-for="(yearItem, j) in form.year_date" :key="j">
                <span>{{ yearItem.date.join("年&") }}年</span>
                <i class="icon el-icon-error" @click="delYear(j)"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="查看月份">
          <div class="monthContent">
            <div class="monthBox" v-for="(item, index) in tempMonth" :key="`1-${index}`">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="selectBox">
                <div class="selectLeft">
                  <el-checkbox class="list" :indeterminate="dateIsIndeterminateForm[index]" v-model="dateCheckForm[index]"
                    @change="
                      handleCheckAllChange_date($event, item.monthArr, index)
                    ">全选</el-checkbox>
                  <div class="list" v-for="(monthItem, j) in item.monthArr" :key="j">
                    <el-checkbox v-model="monthItem.checked" :disabled="monthItem.disabled" :value="monthItem.value"
                      @change="hanldeDateChange(item.monthArr, index)">{{ monthItem.name }}</el-checkbox>
                  </div>
                </div>
                <div class="selectBtn">
                  <MyButton title="合并" @click="monthMerge(item)"></MyButton>
                </div>
                <div class="selectRight">
                  <div class="list" v-for="(monthItem, j) in item.monthDate" :key="j">
                    <span>{{ monthItem.date.join("月&") }}月</span>
                    <i class="icon el-icon-error" @click="delMonth(item.monthDate, j, item)"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="monthBox" v-for="(item, index) in form.year_date" :key="index">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="selectBox">
                <div class="selectLeft">
                  <!-- <el-checkbox
                    class="list"
                    :indeterminate="dateIsIndeterminateForm[index]"
                    v-model="dateCheckForm[index]"
                    @change="
                      handleCheckAllChange_date($event, item.monthArr, index)
                    "
                    >全选</el-checkbox
                  > -->
                  <div class="list" v-for="(monthItem, j) in item.monthArr" :key="j">
                    <el-checkbox v-model="monthItem.checked" :disabled="monthItem.disabled" :value="monthItem.value">{{
                      monthItem.name }}</el-checkbox>
                  </div>
                </div>
                <div class="selectBtn">
                  <MyButton title="合并" @click="monthMerge(item)"></MyButton>
                </div>
                <div class="selectRight">
                  <div class="list" v-for="(monthItem, j) in item.monthDate" :key="j">
                    <span>{{ monthItem.date.join("月&") }}月</span>
                    <i class="icon el-icon-error" @click="delMonth(item.monthDate, j, item)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="收费类型">
          <el-row>
            <el-radio-group v-model="merchants_status" style="margin-left: 10px" @change="temporaryMerchants($event)">
              <el-radio :label="false">正式</el-radio>
              <el-radio :label="true">临时</el-radio>
            </el-radio-group>
          </el-row>
        </el-form-item>
        <el-form-item label="数据类型">
          <el-row v-for="(item, index) in storeType" :key="index" :style="{ marginTop: index ? '10px' : '0px' }">
            <el-col :span="3" v-if="index == 0">
              <el-checkbox v-model="item.checked" :disabled="getStoreDisabled(item)" @change="changeStore">{{ item.name
              }}</el-checkbox>
            </el-col>
            <el-col :span="3" v-else-if="item.name != '临时商户'">
              <el-checkbox v-model="item.checked" :disabled="storeType[0].checked" @change="changeType($event, index)">{{
                item.name }}</el-checkbox>
            </el-col>
            <el-col :span="3" v-else>
              <el-checkbox v-model="item.checked" @change="temporaryMerchants($event)">{{ item.name }}</el-checkbox>
            </el-col>
            <el-col :span="4" :offset="1" v-if="index == 0">
              <el-select :placeholder="`请选择${item.name}`" :disabled="!item.checked" v-model="form.merchants_ids" multiple>
                <el-option :label="list.contact_name" :value="list.merchants_id" v-for="(list, index) in item.data"
                  :key="index"></el-option>
              </el-select>
            </el-col>
            <el-col :span="4" :offset="1" v-else-if="item.name != '临时商户'">
              <el-select :placeholder="`请选择${item.name}`" :disabled="!item.checked"
                v-model="form.property_type[index].merchants_stall_id" multiple>
                <el-option :label="list.merchants_stall_number" :value="list.merchants_stall_id"
                  v-for="(list, index) in item.data" :key="index"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="收费项目">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group class="select-group-inline" v-model="chargesType" @change="hanldeChargeChange">
            <el-checkbox v-for="(item, index) in charges" :key="index" :label="item.valueChecked"
              :disabled="item.typeFlag == 30 && merchants_status">{{ item.contract_fee_name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="费用类型">
          <el-checkbox v-if="!isStoreType" :indeterminate="isIndeterminate_type" v-model="checkAll_type"
            @change="handleCheckAllChange_type">全选</el-checkbox>
          <el-checkbox v-model="form.pay_charge.total_status" v-if="!isStoreType">应收金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.pay_status">已收金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.not_pay_status" v-if="!isStoreType">未收金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.discount_status" v-if="!isStoreType">减免金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.zero_price_status" v-if="!isStoreType">抹零金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.coupon_status" v-if="!isStoreType">优惠金额</el-checkbox>
          <el-checkbox v-model="form.pay_charge.rate_status" v-if="!isStoreType">收费率</el-checkbox>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="search" right></MyButton>
          <MyButton title="导出" @click="exportExcel" :accessId="55054"></MyButton>
        </el-form-item>
      </el-form>
      <div class="yc-table">
        <el-table :data="tableData" id="tableXlXS" v-loading="loading" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="数据类型" fixed width="150px">
            <el-table-column prop="name" label="数据项" width="150px"></el-table-column>
          </el-table-column>
          <el-table-column v-for="(item, index) in colList" :key="index">
            <template slot="header" slot-scope="scope">
              <div class="tableHeadrBox">
                <span>{{ item.year_title }}</span>
                <div class="tableIcon">
                  <i class="el-icon-circle-close" @click="cellClick(scope, index)"></i>
                </div>
              </div>
            </template>
            <el-table-column prop="name" v-for="(child, j) in item.child" :key="j">
              <template slot="header" slot-scope="scope">
                <div class="tableHeadrBox">
                  <span>{{ child.title }}</span>
                  <div class="tableIcon">
                    <i class="el-icon-circle-close" @click="cellClick(scope, index, j, item.child[j - 1])"></i>
                    <el-button type="text" v-if="
                      !(index == 0 && j == 0) &&
                      !child.showFlag &&
                      !child.increase
                    " @click="growthRate(child, index, j)">增长率</el-button>
                  </div>
                </div>
              </template>
              <el-table-column :label="child.increase ? `${pay.name}增长率` : pay.name" width="120"
                v-for="(pay, k) in payList" :key="k" v-if="pay.show">
                <template slot-scope="scope">{{ setCost(child, pay.field, scope.row)
                }}{{ child.increase ? "%" : "" }}</template>
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="summation">
          <div class="listBox">合计</div>
          <div class="summationContent" ref="summationRef" @scroll="handleScroll">
            <div class="summationBox" v-for="(item, i) in colList" :key="i">
              <div class="summationBoxItem" v-for="(list, j) in item.child" :key="j">
                <div class="list" v-for="(pay, k) in payList" :key="k" v-if="pay.show">
                  {{ getSummation(list, pay.field, i, j, k) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 分析数据 -->
      <el-table ref="scorllTableRef" :data="subentry" style="margintop: 20px" v-if="!isStoreType"
        :span-method="objectSpanMethod" v-loading="loading" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="分项数据" fixed width="300px">
          <el-table-column prop="merchants_id" :label="form.merchants_ids.length > 0 ? '商户姓名' : '商铺号'" width="150px">
            <template slot-scope="scope">
              <span v-if="scope.row.merchants_id">{{
                merchantsListMap[scope.row.merchants_id].contact_name
              }}</span>
              <span v-else>{{
                stallTypeMap[scope.row.merchants_stall_id]
                  .merchants_stall_number
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="数据项" width="150px"></el-table-column>
        </el-table-column>
        <el-table-column v-for="(item, index) in subentryColList" :key="index">
          <template slot="header" slot-scope="scope">
            <div class="tableHeadrBox">
              <span>{{ item.year_title }}</span>
              <div class="tableIcon">
                <i class="el-icon-circle-close" @click="subentryClick(scope, index)"></i>
              </div>
            </div>
          </template>
          <el-table-column prop="name" v-for="(child, j) in item.child" :key="j">
            <template slot="header" slot-scope="scope">
              <div class="tableHeadrBox">
                <span>{{ child.title }}</span>
                <div class="tableIcon">
                  <i class="el-icon-circle-close" @click="subentryClick(scope, index, j)"></i>
                </div>
              </div>
            </template>
            <el-table-column :label="pay.name" width="120" v-for="(pay, k) in payList" :key="k" v-if="pay.show">
              <template slot-scope="scope">{{
                setStoreCost(child.child, pay.field, scope.row)
              }}</template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "dataReport",
  data () {
    return {
      chargesType: [],
      isStoreType: false,
      //全选
      checkAll: false,
      isIndeterminate: false,
      //费用全选
      checkAll_type: false,
      isIndeterminate_type: false,
      //日期全选
      dateCheckForm: [],
      dateIsIndeterminateForm: [],
      merchants_status: false,
      marketList: [],
      formData: {},
      isFinance: process.env.VUE_APP_IS_FINANCE == "-1",
      userMarket: null,
      totalRow: {},
      totalData: {}, //合计数据
    };
  },
  watch: {
    "form.pay_charge": {
      handler: function (newValue) {
        const valueList = Object.values(newValue).filter((item) => item);
        let checkedCount = valueList.length;
        this.checkAll_type = checkedCount === 7;
        this.isIndeterminate_type = checkedCount > 0 && checkedCount < 7;
      },
      deep: true,
    },
    "form.pay_charge.rate_status": {
      handler: function (newValue) {
        console.log("newValue", newValue);
        if (newValue) {
          this.form.pay_charge.total_status = true;
          this.form.pay_charge.pay_status = true;
          this.form.pay_charge.not_pay_status = true;
        }
      },
    },
    "form.pay_charge.total_status": {
      handler: function (newValue) {
        if (!newValue) {
          this.form.pay_charge.rate_status = false;
        }
      },
    },
    "form.pay_charge.not_pay_status": {
      handler: function (newValue) {
        if (!newValue) {
          this.form.pay_charge.rate_status = false;
        }
      },
    },
    "form.pay_charge.pay_status": {
      handler: function (newValue) {
        if (!newValue) {
          this.form.pay_charge.rate_status = false;
        }
      },
    },
  },
  computed: {
    ...mapState("dataReport", [
      "form",
      "yearArr",
      "monthArr",
      "charges",
      "storeType",
      "tempMonth",
      "tableData",
      "colList",
      "loading",
      "payList",
      "subentry",
      "merchantsListMap",
      "subentryColList",
      "stallTypeMap",
    ]),
  },
  created () {
    console.log("isFinance", this.isFinance);

    this.initYear();
    this.initMonth();
    this.getStoreType();
    this.getCharges();
    this.getMarketList();
    this.userMarket =
      window.localStorage.getItem("userInfo").length > 0
        ? JSON.parse(window.localStorage.getItem("userInfo")).market_id
        : null;
  },
  beforeDestroy () {
    // this.setTempMonth();
    this.$store.commit("dataReport/clearState");
  },
  methods: {
    ...mapMutations("dataReport", [
      "initYear",
      "initMonth",
      "setTempMonth",
      "setColList",
      "setTableData",
      "setLoading",
      "setSubentry",
      "setSubentryColList",
    ]),
    ...mapActions("dataReport", ["getStoreType", "getCharges", "searchIndex"]),
    getMarketList () {
      this.$request.HttpGet("/market/list").then((res) => {
        this.marketList = res.data.list;
        console.log("res :>> ", res);
      });
    },
    // 年份合并
    yearMerge () {
      let tempArr = [];
      this.yearArr.forEach((item) => {
        if (item.checked && !item.disabled) {
          tempArr.push(item.value);
          item.disabled = true;
          this.setTempMonth();
        }
      });
      if (tempArr.length > 0) {
        let data = JSON.stringify(this.monthArr);
        this.form.year_date.push({
          title: `${tempArr.join("年&")}年`,
          date: tempArr,
          monthDate: [],
          monthArr: JSON.parse(data),
        });
      }
      this.dateCheckForm = [];
      this.dateIsIndeterminateForm = [];
    },
    // 年份发生变化的时候要对应添加月份
    changeYear (item) {
      if (item.checked) {
        let data = JSON.stringify(this.monthArr);
        this.tempMonth.push({
          title: item.name,
          monthDate: [],
          monthArr: JSON.parse(data),
        });
      } else {
        this.tempMonth.forEach((tempMonthItme, i) => {
          if (item.name == tempMonthItme.title) {
            this.tempMonth.splice(i, 1);
          }
        });
      }
    },
    // 年份合并数据点击删除
    delYear (index) {
      this.yearArr.forEach((item) => {
        if (this.form.year_date[index].date.indexOf(item.value) != -1) {
          item.disabled = false;
          item.checked = false;
        }
      });
      this.form.year_date.splice(index, 1);
    },
    // 月份合并数据删除
    delMonth (item, index, parent) {
      parent.monthArr.forEach((list) => {
        if (item[index].date.indexOf(list.value) != -1) {
          list.disabled = false;
          list.checked = false;
        }
      });
      item.splice(index, 1);
    },
    // 月份合并
    monthMerge (item) {
      let tempArr = [];
      item.monthArr.forEach((month) => {
        if (month.checked && !month.disabled) {
          tempArr.push(month.value);
          month.disabled = true;
        }
      });
      if (tempArr.length > 0) {
        item.monthDate.push({
          title: tempArr.join("月&") + "月",
          date: tempArr,
        });
      }
    },
    getStoreDisabled (item) {
      let is = false;
      this.storeType.forEach((item, i) => {
        if (i != 0 && item.checked) {
          is = true;
          return is;
        }
      });
      return is;
    },
    changeStore (event) {
      if (!event) {
        this.form.merchants_ids = [];
      }
    },
    temporaryMerchants (event) {
      if (event) {
        this.form.merchants_id = -1;
        this.isStoreType = true;
        this.storeType.forEach((item) => {
          if (item.name != "临时商户") {
            item.checked = false;
          }
        });
        if (this.form.property_type) {
          this.form.property_type.forEach((list) => {
            list.merchants_stall_id = [];
          });
        }
        this.chargesType = [];
        this.form.pay_charge.total_status = false;
        this.form.pay_charge.not_pay_status = false;
        this.form.pay_charge.discount_status = false;
        this.form.pay_charge.zero_price_status = false;
        this.form.pay_charge.coupon_status = false;
        this.form.pay_charge.rate_status = false;
      } else {
        this.isStoreType = false;
        this.form.merchants_id = 0;
      }
      this.checkAll = false;
      this.isIndeterminate = false;

      // this.paymentTypeList.forEach(item => {
      //   item.disabled = false
      //   if (this.dayFeeList.includes(item.id) && this.tempMerchant) {
      //     item.disabled = true
      //   }
      // })
    },
    changeType (event, i) {
      if (!event) {
        this.form.property_type[i].merchants_stall_id = [];
      }
    },
    cellClick (scope, i, j, item) {
      this.totalData = {};
      if (item) item.showFlag = false;

      if (typeof j == "undefined") {
        this.colList.splice(i, 1);
      } else {
        this.colList[i].child.splice(j, 1);
      }
      const data = this.colList;
      const tableData = this.tableData;
      this.setColList([]);
      this.setTableData([]);
      setTimeout(() => {
        this.setColList(data);
        this.setTableData(tableData);
      });
    },
    subentryClick (scope, i, j) {
      if (typeof j == "undefined") {
        this.subentryColList.splice(i, 1);
      } else {
        this.subentryColList[i].child.splice(j, 1);
      }
      const tableData = this.subentry;
      const data = this.subentryColList;
      this.setSubentryColList([]);
      this.setSubentry([]);
      setTimeout(() => {
        this.setSubentryColList(data);
        this.setSubentry(tableData);
      });
    },
    assembleChargesType () {
      let dataArr = [];
      this.charges.forEach((item) => {
        if (this.chargesType.indexOf(item.valueChecked) != -1) {
          dataArr.push({
            name: item.contract_fee_name,
            fee_id: item.id,
            type: item.typeFlag,
          });
        }
      });
      return dataArr;
    },
    // 检测年份和月份没有合并的数据
    detectionTime () {
      const yearData = [].concat(
        JSON.parse(JSON.stringify(this.form.year_date)),
        JSON.parse(JSON.stringify(this.tempMonth))
      );
      yearData.forEach((item) => {
        if (!item.date) {
          item.date = [parseInt(item.title).toString()];
        }
        item.monthArr.forEach((month) => {
          if (month.checked && !month.disabled) {
            item.monthDate.push({
              title: month.name,
              date: [month.value],
            });
          }
        });
      });
      return yearData;
    },
    getDataType () {
      const dataType = [];
      this.storeType.forEach((item, index) => {
        if (index == 0 && item.checked && this.form.merchants_ids.length < 1) {
          const ids = [];
          item.data.forEach((data) => {
            ids.push(data.merchants_id);
          });
          this.form.merchants_ids = ids;
        } else if (index != 0 && item.checked) {
          let ids = [];
          if (this.form.property_type[index].merchants_stall_id.length < 1) {
            item.data.forEach((data) => {
              ids.push(data.merchants_stall_id);
            });
          } else {
            ids = this.form.property_type[index].merchants_stall_id;
          }
          dataType.push({
            name: item.property_type_id,
            merchants_stall_id: ids,
          });
        }
      });
      return dataType;
    },
    // 设置表格显示的字段
    getPayType () {
      for (let i in this.payList) {
        this.payList[i].show = false;
        if (this.form.pay_charge[this.payList[i].checked]) {
          this.payList[i].show = true;
        }
      }
    },
    search () {
      const yearData = this.detectionTime();
      if (!yearData.length) {
        this.$message.error("请选择查询时间");
        return;
      }
      if (!Object.values(this.form.pay_charge).includes(true)) {
        this.$message.error("请选择费用类型");

        return;
      }
      // 判断是否有收费项目，如果有就进行数据组装
      let fee_charge = [];
      if (this.chargesType.length > 0) {
        fee_charge = this.assembleChargesType();
      }

      const dataType = !this.isStoreType ? this.getDataType() : [];
      this.getPayType();
      this.setColList([]);
      this.setTableData([]);
      this.setSubentry([]);
      this.setSubentryColList([]);
      delete this.form.merchants_id;

      this.setLoading(true);
      this.searchIndex({
        ...this.form,
        merchants_status: this.merchants_status,
        year_date: yearData,
        property_type: dataType.length ? dataType : null,
        fee_charge: fee_charge.length ? fee_charge : null,
        search_market_id: this.isFinance
          ? this.formData.search_market_id || "-1"
          : this.userMarket,
      });

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scorllTableRef?.doLayout();
        }, 200);
        // 解决表格错位
      });
    },
    caclRatio (key_total_price, key_pay_price) {
      if (key_total_price == 0) return 0;
      const res =
        Math.round((1 - key_pay_price / key_total_price) * 100 * 100) / 100;
      return res;
    },
    setCost (child, name, row) {
      for (let i in child.data) {
        if (child.data[i].property_type_id == row.fee_id) {
          if (name == "price_ratio") {
            return this.caclRatio(
              child.data[i].key_total_price,
              child.data[i].key_pay_price,
              name
            );
          }
          return child.data[i][name];
        }
      }
    },
    setStoreCost (child, name, row) {
      if (row.merchants_id) {
        if (child[row.merchants_id] && child[row.merchants_id].data) {
          for (let i in child[row.merchants_id].data) {
            if (
              child[row.merchants_id].data[i].property_type_id == row.fee_id
            ) {
              return child[row.merchants_id].data[i][name];
            }
          }
        }
      } else {
        if (
          child[row.merchants_stall_id] &&
          child[row.merchants_stall_id].data
        ) {
          for (let i in child[row.merchants_stall_id].data) {
            if (
              child[row.merchants_stall_id].data[i].property_type_id ==
              row.fee_id
            ) {
              return child[row.merchants_stall_id].data[i][name];
            }
          }
        }
      }
    },
    hanldeTotalData (y, m, i, total) {
      if (!this.totalData[y]) {
        this.totalData[y] = {};
      }
      if (!this.totalData[y][m]) {
        this.totalData[y][m] = {};
      }
      this.totalData[y][m][i] = total;
    },
    getSummation (list, name, yearIndex, monthIndex, itemIndex) {
      let total = 0;
      list.data.forEach((item) => {
        total = total + item[name] * 100;
      });
      if (name == "key_pay_price") {
        this.totalRow["key_pay_price"] = Math.round(total) / 100;
      }
      if (name == "key_total_price") {
        this.totalRow["key_total_price"] = Math.round(total) / 100;
      }

      if (name == "price_ratio") {
        const ratioTotal = this.caclRatio(
          this.totalRow["key_total_price"],
          this.totalRow["key_pay_price"],
          name
        );
        this.hanldeTotalData(yearIndex, monthIndex, itemIndex, ratioTotal);
        return ratioTotal;
      } else {
        this.hanldeTotalData(
          yearIndex,
          monthIndex,
          itemIndex,
          Math.round(total) / 100
        );
      }
      return Math.round(total) / 100;
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.rowspan) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    growthRate (item, i, j) {
      item.showFlag = true;
      let newObj = {};
      let last = {};
      let tempI = 0;
      let tempJ = 0;
      if (j > 0) {
        if (this.colList[i].child[j - 1].increase) {
          tempJ = j - 2;
          tempI = i;
        } else {
          tempJ = j - 1;
          tempI = i;
        }
      }
      if (j == 0) {
        let len = this.colList[i - 1].child.length - 1;
        tempJ = len - 1;
        tempI = i - 1;
      }
      last = this.colList[tempI].child[tempJ];
      console.log("object :>> ", item, last, this.colList, i, j);

      newObj = {
        data: JSON.parse(JSON.stringify(item.data)),
        increase: true,
        title: `${last.year_title}${last.title} : ${item.year_title}${item.title}`,
      };
      for (let i in newObj.data) {
        newObj.data[i].coupon_price = this.varianceCalculation(
          item.data[i].coupon_price,
          last.data[i].coupon_price
        );
        newObj.data[i].give_discount = this.varianceCalculation(
          item.data[i].give_discount,
          last.data[i].give_discount
        );
        newObj.data[i].key_pay_price = this.varianceCalculation(
          item.data[i].key_pay_price,
          last.data[i].key_pay_price
        );
        newObj.data[i].key_total_price = this.varianceCalculation(
          item.data[i].key_total_price,
          last.data[i].key_total_price
        );
        newObj.data[i].key_update_price = this.varianceCalculation(
          item.data[i].key_update_price,
          last.data[i].key_update_price
        );
        newObj.data[i].price_ratio = this.varianceCalculation(
          this.caclRatio(
            item.data[i].key_total_price,
            item.data[i].key_pay_price
          ),
          this.caclRatio(
            last.data[i].key_total_price,
            last.data[i].key_pay_price
          )
        );
        newObj.data[i].zero_price = this.varianceCalculation(
          item.data[i].zero_price,
          last.data[i].zero_price
        );
      }
      this.colList[i].child.splice(j + 1, 0, newObj);
      setTimeout(() => {
        this.$forceUpdate();
      }, 200);
    },
    varianceCalculation (molecule, denominator) {
      if (molecule == 0 && denominator == 0) {
        return 0;
      }
      if (denominator == 0) {
        return 100;
      }

      return parseFloat(((molecule - denominator) / denominator) * 100).toFixed(
        2
      );
    },
    exportExcel () {
      let table = document.querySelector("#tableXlXS").cloneNode(true);
      // table.removeChild(table.querySelector(".el-table__fixed-right"));
      // let child = table.querySelectorAll('.el-button');
      // for (let i in child) {
      //   table.removeChild(child[i]);
      // }
      table.removeChild(table.querySelector(".el-table__fixed"));
      var wb = XLSX.utils.table_to_book(table, { raw: true });
      // 因为element-ui的表格的fixed属性导致多出一个table，会下载重复内容，这里删除掉
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "数据报表.xlsx"
        ); //导出文件的名字
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    //处理滚动条
    handleScroll () {
      document.getElementsByClassName("el-table__body-wrapper")[0].scrollLeft =
        this.$refs.summationRef.scrollLeft;
    },
    //全选逻辑
    handleCheckAllChange (val) {
      this.chargesType = val
        ? this.charges.map((item) => {
          if (this.merchants_status) {
            if (item.typeFlag != 30) {
              return item.valueChecked;
            }
          } else {
            return item.valueChecked;
          }
        })
        : [];
      this.isIndeterminate = false;
    },
    hanldeChargeChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.charges.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.charges.length;
    },
    handleCheckAllChange_type (val) {
      const keysList = Object.keys(this.form.pay_charge);
      if (val) {
        keysList.forEach((key) => {
          this.form.pay_charge[key] = true;
        });
      } else {
        keysList.forEach((key) => {
          this.form.pay_charge[key] = false;
        });
      }
      this.isIndeterminate_type = false;
    },
    handleCheckAllChange_date (check, dateList, index) {
      const checkItemArry = dateList; //获取当前月份列表，checked 选中为true
      if (check) {
        checkItemArry.forEach((item) => {
          item.checked = true;
        });
      } else {
        checkItemArry.forEach((item) => {
          item.checked = false;
        });
      }
      this.dateIsIndeterminateForm[index] = false;
    },
    hanldeDateChange (item, index) {
      const checkedCount = item.filter((item) => item.checked).length;
      this.dateCheckForm[index] = checkedCount === item.length;
      this.dateIsIndeterminateForm[index] =
        checkedCount > 0 && checkedCount < item.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.monthContent {
  display: flex;
  flex-wrap: wrap;

  .monthBox {
    border: 1px solid #ededed;
    width: 520px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    .title {
      text-align: center;
    }
  }
}

.selectBox {
  display: flex;
  align-items: center;

  .selectLeft {
    width: 200px;
    border: 1px solid #ebeef5;
    padding: 10px;
    box-sizing: border-box;
    height: 123px;
    overflow-y: auto;
  }

  .selectBtn {
    margin: 0 20px;
  }

  .selectRight {
    min-width: 200px;
    border: 1px solid #ebeef5;
    padding: 10px;
    box-sizing: border-box;
    height: 123px;
    overflow-y: auto;

    .list {
      line-height: 25px;
    }
  }
}

.tableHeadrBox {
  .tableIcon {
    i {
      position: absolute;
      right: 10px;
      top: -3px;
      font-size: 18px;
    }

    .el-button {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
}

.yearBox {
  border: 1px solid #ededed;
  padding: 0px 10px;

  .list {
    border-bottom: 1px solid #ededed;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.summation {
  display: flex;
  width: 100%;

  .listBox {
    width: 151px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    box-sizing: border-box;
  }

  .summationContent {
    width: calc(100% - 151px);
    overflow-x: auto;
    display: flex;
  }

  .summationBox {
    display: flex;

    .summationBoxItem {
      display: flex;

      .list {
        width: 120px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-bottom: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        box-sizing: border-box;
      }
    }
  }
}

/deep/ .cell {
  overflow: unset !important;
}

// .summation {
//   .summationContent::-webkit-scrollbar {
//     display: none;
//   }
// }

.select-group-inline {
  display: inline-block;
  margin-left: 30px;
}
</style>
